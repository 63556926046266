@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.font-montserrat {
	font-family: 'Montserrat', sans-serif;
}
