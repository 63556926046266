html {
	margin-left: calc(100vw - 100%);
}

body {
	font-family: arial;
}

/* Color Pallete */
.color-white {
	color: #ffffff;
}

.color-yellow {
	color: #ffcc33;
}

.color-grey {
	color: #999999;
}

.color-dark-grey {
	color: #666666;
}

.color-charcoal {
	color: #373735;
}

.bg-color-yellow {
	background-color: #ffcc33;
}

.bg-color-grey {
	background-color: #999999;
}

.bg-color-dark-grey {
	background-color: #666666;
}

.bg-color-charcoal {
	background-color: #373735;
}

.nav > li > a {
	padding: 5px 20px;
}

.navbar-default .navbar-nav > li > a {
	color: #666666;
	padding: 5px 10px;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
	color: #373735;
	background-color: #ffcc33;
	font-weight: bold;
}

.nav-collapse a div {
	font-size: 1.5em;
	padding: 15px;
}

/* Schedule Tabs */
.react-tabs__tab-list {
	border: 0px;
}

.react-tabs__tab {
	background-color: #373735;
	color: #ffffff;
	border: 0px;
	border-radius: 0px;
}

.react-tabs__tab--selected {
	background-color: #ffcc33;
	color: #373735;
	border: 0px;
	border-radius: 0px;
	font-weight: bold;
}

.daytab .react-tabs__tab--selected:after {
	content: "";
	position: absolute;
	bottom: -9px;
	left: 30px;
	border-width: 10px 10px 0;
	border-style: solid;
	border-color: #ffcc33 transparent;
	display: block;
	width: 0;
}

.banner-container {
	height: 270px;
}

.banner-container .message1 {
	position: absolute;
	top: 30px;
	left: 30px;
	color: #ffffff;
	font-weight: bold;
	font-size: 1.9em;
}

.banner-container .message2 {
	position: absolute;
	bottom: 20px;
	left: 30px;
	color: #ffffff;
	font-weight: bold;
}

.banner-container .logo-overlay {
	display: none;
}

.venue-img {
	width: 100%;
	margin-bottom: 20px;
}

.nav-collapse {
	padding-right: 0px;
	margin-top: 0px;
}

.attendees-align {
	text-align: center;
}

.attendeesImages {
	padding: 10px 0px;
}

.attendeesImages .attendeesBox {
	overflow: hidden;
	width: 140px;
	height: 210px;
	margin: auto;
}

.short-description {
	padding: 30px 0px 15px 0px;
}

.short-description .player-wrapper {
	display: block;
	margin-bottom: 20px;
	height: 200px;
}

@media (min-width: 425px) {
	.attendeesImages .attendeesBox {
		width: 190px;
		height: 260px;
	}
}

@media (min-width: 768px) {
	.nav-collapse {
		padding-right: 15px;
		margin-top: 70px;
	}

	.nav-collapse a div {
		font-size: 1em;
		padding: 0px;
	}

	.banner-container {
		height: 350px;
	}

	.banner-container .message1 {
		font-size: 3em;
	}

	.banner-container .message2 {
		font-size: 1.6em;
	}

	.navbar-nav > .active > a:after {
		content: "";
		position: absolute;
		bottom: -9px;
		left: 10px;
		border-width: 10px 10px 0;
		border-style: solid;
		border-color: #ffcc33 transparent;
		display: block;
		width: 0;
		z-index: 1000;
	}

	.attendees-align {
		text-align: left;
	}

	.attendeesImages .attendeesBox {
		width: 170px;
		height: 240px;
	}

	.banner-container .logo-overlay {
		display: block;
		position: absolute;
		right: 10px;
		bottom: 10px;
		width: 100px;
	}

	.short-description .player-wrapper {
		float: right;
		margin: 0px 0px 10px 20px;
		width: 49%;
		height: 300px;
	}
}

@media (min-width: 1024px) {
	.attendeesImages .attendeesBox {
		width: 190px;
		height: 260px;
	}
}

@media (min-width: 1200px) {
	.attendeesImages .filterGray {
		filter: grayscale(100%);
	}

	.attendeesImages .filterGray:hover {
		filter: grayscale(5%);
	}
}
